.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(122, 122, 122);
  text-align: center;
}

.header_brandname {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_brandname h1 {
  color: black;
}

.header_brandname h1:hover {
  color: blue;
}

.header_links {
  align-items: center;
  justify-content: center;
  color: black;
}

.header_link {
  padding: 10px;
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.header_link:hover {
  text-decoration: underline;
}

/* Position and sizing of burger button */
.bm-burger-button {
  width: 20px;
  height: 16px;
  right: 36px;
  top: 36px;
  position: absolute;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  position: fixed;
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: -0.5px;
  height: 100vh;
}

/* General sidebar styles */
.bm-menu {
  background: #070A52;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  border-left: 1px solid black;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}