.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.team-member {
    width: 300px;
    padding: 25px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
}

.team-member h2 {
    margin-bottom: 10px;
}

.team-member img {
    width: 200px;
    height: auto;
    border-radius: 50%;
    margin-bottom: 20%;
}

.teamPage_title {
    text-align: center;
    padding: 30px;
    font-size: 40px;
}