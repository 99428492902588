.registerPage_form {
    padding: 30px;
    width: 80%;
    margin: auto;
    display: block;
    height: 180vh;
    overflow: hidden;
}

.introduction {
    padding: 50px;
    background-color: #17594a;
    color: white;
    text-align: center;
    align-items: center;
}

.introduction h1 {
    padding: 50px 0px 25px 0px;
}

.introduction p {
    padding: 25px 10px 0px 10px;
    font-size: 20px;
}

@media only screen and (max-width: 740px) {
    .registerPage_form {
        height: 200vh;
        width: 90%;
    }
}


@media only screen and (max-width: 510px) {
    .registerPage_form {
        height: 230vh;
        width: 95%;
    }
}

.information {
    padding: 50px;
    text-align: center;
}

.information p {
    margin-top: 20px;
    padding: 20px;
    width: 80%;
    margin: auto;
}

.organization {
    margin-top: 20px;
}

.schedule {
    background-color: #13005A;
    color: white;
    padding: 50px;
    text-align: center;
    align-items: left;
}

.schedule h2 {
    padding: 10px;
    text-align: center;
}

.schedule h3 {
    margin: 30px 0px 5px 0px;
    font-size: 25px;
}

.organization {
    text-align: center;
    padding: 30px;
}