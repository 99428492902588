.homepage_section1 {
  background-color: #35a29f;
  align-items: center;
  justify-content: space-between;
}

.homepage_section_1_txt {
  text-transform: capitalize;
  color: white;
  text-align: center;
  padding: 70px;
  font-size: 30px;
}

@media only screen and (max-width: 1150px) {
  .homepage_section_1_txt {
    font-size: 25px;
    padding: 60px;
  }
}

@media only screen and (max-width: 890px) {
  .homepage_section_1_txt {
    font-size: 20px;
    padding: 50px;
  }
}

.homepage_section_2 {
  display: flex;
  align-items: center;
  background-color: #17594a;
}

.homepage_section_2_txt {
  width: 50%;
  text-align: center;
  padding: 30px;
  color: white;
}

.homepage_section_2_txt h2 {
  font-size: 30px;
  padding: 20px;
}

.homepage_section_2_txt p {
  font-size: 15px;
}

.homepage_section_2_img {
  width: 50%;
  padding: 0px;
  max-height: 600px;
}

@media only screen and (max-width: 985px) {
  .homepage_section_2_txt h2 {
    font-size: 25px;
    padding: 10px;
  }

  .homepage_section_2_txt p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 820px) {
  .homepage_section_2 {
    display: grid;
  }

  .homepage_section_2_txt {
    width: 100%;
  }

  .homepage_section_2_img {
    width: 100%;
  }
}

.homepage_section_3_title {
  text-align: center;
  padding: 30px;
  font-size: 30px;
}

.homepage_section_3_cards {
  align-self: center;
  align-items: center;
  display: grid;
  grid-column-gap: 90px;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-auto-rows: 430px;
  margin: auto;
  justify-content: center;
  padding: 20px;
}

@media only screen and (max-width: 1544px) {
  .homepage_section_3_cards {
    width: 70%;
  }
}

@media only screen and (max-width: 1045px) {
  .homepage_section_3_cards {
    width: 100%;
  }
}

.homepage_section_3_card {
  margin-bottom: 30px;
}

.homepage_section_4 {
  padding: 20px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  background-color: #13005A;
}

.homepage_section_4_submit {
  padding: 10px;
  width: 50%;
}

.homepage_section_4_browse {
  padding: 10px;
  border-left: 1px solid rgb(168, 168, 168);
  width: 50%;
}

@media only screen and (max-width: 494px) {
  .homepage_section_4_submit {
    padding: 10px;
    width: 100%;
  }

  .homepage_section_4_browse {
    padding: 10px;
    border-left: 1px solid rgb(168, 168, 168);
    width: 100%;
  }
}

.announcement {
  background-color: #13005a;
}

.homepage_section_5 {
  text-align: center;
}

.homepage_section_6 {
  padding: 20px;
  color: white;
  background-color: #17594a;
}